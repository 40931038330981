<template>
  <div id="app">
    <component :is="component" />
  </div>
</template>

<script>
import Map from './components/Map.vue'

export default {
  components: {
    Map
  },
  data() {
    return {
      component: Map
    }
  }
}
</script>

<style>
 @tailwind base;
 @tailwind components;
 @tailwind utilities;

 body { 
  background-color: black;
 }

 ::-webkit-scrollbar {
  display: none;
 }
</style>
