<template>
 <div>
  <div id="map-holder">
   <div id="map" class="absolute left-0 top-0 h-full w-[74%]"></div>
  </div>
  <div id="menu" class="absolute bg-black w-1/4 h-[99%] overflow-auto right-0 top-0">
   <input type="text" placeholder="Search for People" v-model="search" class="w-[93.5%] p-2.5 m-2.5 border-none bg-gray-900 text-white text-lg rounded"/>
   <div id="peopleList" class="m-2.5 p-2.0 bg-gray-900 rounded text-white text-lg h-[90%] w-[93.5%] overflow-scroll">
    <div v-for="person in filteredPeople" :key="person.id" @click="setMapView(person.lat, person.lng)" class="m-2.5 p-2.5 bg-gray-700 rounded text-white text-lg flex flex-col items-start hover:cursor-pointer">
     <div class="flex items-center mb-0 hover:cursor-pointer">
      <img :src="person.img" alt="Person Image" class="w-12 h-12 rounded-full mr-3 hover:cursor-pointer" />
      <span class="font-semibold hover:cursor-pointer">
       {{ person.name }}
       <p class="text-sm">{{ person.town }}</p>
      </span>
     </div>
    </div>
   </div>
  </div>
 </div>
</template>

<script>
 import axios from 'axios';
 import { ref } from 'vue';
 import 'leaflet/dist/leaflet.css';
 import * as L from 'leaflet';

 export default {
  name: 'MapComponent',
  data() {
   return {
     people: [],
     search: '',
     initialMap: ref(null),
   };
  },
  computed: {
   filteredPeople() {
    return this.people.filter((person) =>
     person.name.toLowerCase().includes(this.search.toLowerCase()) || person.town.toLowerCase().includes(this.search.toLowerCase())
    );
   },
  },
  created() {
   axios.get('https://home-api.keiranh.com/locations').then((response) => {
    this.people = response.data;
   }).catch((error) => {
    console.log(error);
   });
  },
  methods: {
   setMapView(lat, lng) {
    if (this.initialMap) {
     this.initialMap.setView([lat, lng], 10);
    }
   },
  },
  mounted() {
   this.initialMap = L.map('map').setView([53.5074, -7.1272], 6);
   L.tileLayer('https://tile.openstreetmap.org/{z}/{x}/{y}.png', {
    maxZoom: 10,
    minZoom: 3,
    zoomControl: true,
    zoomAnimation: false,
    attribution: '',
    className: 'map-tiles'
   }).addTo(this.initialMap);

   axios.get('https://home-api.keiranh.com/locations').then((response) => { 
    console.log(response.data);
    response.data.forEach((person) => {
     const customIcon = L.icon({
      iconUrl: 'https://www.clker.com/cliparts/0/V/t/A/W/N/google-maps-gris-hi.png',
      iconSize: [15, 25],
      iconAnchor: [7.5, 12.5],
      popupAnchor: [0, 0],
     });

     let markerExists = false;

     this.initialMap.eachLayer((layer) => {
      if (layer instanceof L.Marker) {
       const { lat, lng } = layer.getLatLng();
       if (lat === Number(person.lat) && lng === Number(person.lng)) {
        layer.bindPopup(`${layer.getPopup().getContent()}<br><b>${person.name}</b>`);
        markerExists = true;
       }
      }
     });
     if (!markerExists) {
      L.marker([person.lat, person.lng], { icon: customIcon }).addTo(this.initialMap).bindPopup(`<b>${person.name}</b>`);
     }
    });
   });
  },
};
</script>

<style scoped>
 #map { 
  height: 100%;
  width: 100%;
  left: 1%;
  top: 1.25%;
 }

 #map-holder {
  position: absolute;
  left: 0;
  top: 0;
  height: 96.5%;
  width: 74%;
 }

 input:focus {
  outline: none;
 }
</style>
